.pill {
  display: inline-flex;
  border-radius: 19px;
  cursor: pointer;
  width: fit-content;
  height: 34px;
  padding: 12px;
  box-sizing: border-box;
  align-items: center;

  @include e('chip') {
    padding: 4px 12px;
    height: 24px;
    font-size: 12px;
    cursor: default;
  }

  @include e('selected') {
    background: getColor('lightbeige.80');
  }

  @include e('outlined') {
    border: 1px solid getColor('grey.20');
  }
}
