.icon-view-detail {
  margin-top: 4px;
}

.icon-replay {
  margin-top: -2px;
  margin-left: 4px;
}

.icon-trash {
  margin-left: 4px;
}
