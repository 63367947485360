.item {
  display: flex;
  flex-direction: column;
  padding: 6px;

  @include e('label') {
    font-weight: 400;
    font-size: 14px;
    color: getColor('primary.60');
    margin-bottom: 8px;
  }

  @include e('value') {
    font-weight: 700;
    font-size: 16px;
    color: getColor('grey.base');
  }
}
