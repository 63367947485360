.profile {
  .dropdown__title {
    font-weight: 600;
  }
  .dropdown__list {
    right: 0;
    left: auto;
    width: 260px;

    li {
      a {
        @include display-flex(row, center, left);
      }
    }
  }
}

.profile-img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: getColor('lightbeige.60');
  margin-right: getSpacing('2x');
  margin-top: -4px;
}
