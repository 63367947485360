.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 2px rgba(68, 68, 68, 0.15));
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin-top: -8px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
