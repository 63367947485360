.table {
  max-width: 100%;
  overflow-x: auto;
}

.tabulator-row {
  border-bottom: 1px solid getColor('grey.5');
  &.tabulator-row-odd {
    background: #f5faff;
  }
  &.tabulator-row-even {
    background: getColor('white.base');
  }

  &.tabulator-selectable {
    &:hover {
      background: getColor('primary.10');
    }
  }

  &.highlight-row {
    background: getColor('warning.20');
  }

  &.dimmed-row {
    background: getColor('grey.10');
    .highlight-col {
      background: getColor('grey.20') !important;
    }
  }

  &.danger-row {
    background: getColor('danger.40');
  }

  &.total-row {
    background: getColor('secondary.20');
  }

  &.tabulator-selected {
    background: getColor('primary.10');
  }

  &.is-edited {
    background: getColor('warning.10');
    border-left: 2px solid getColor('warning.base');

    .highlight-col {
      background: getColor('warning.10') !important;
    }
  }

  .tabulator-cell {
    border-right: none;
    padding: 0.375rem getSpacing('4x');
    color: getColor('primary.base');

    &.highlight-col {
      background: getColor('secondary.40');
    }

    &.action-btn-cell {
      padding-left: 0;
      padding-right: 0;
      border-right: 0;
    }

    &.tabulator-editable {
      position: relative;

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        border-left: 2px solid getColor('grey.60');
        border-bottom: 2px solid getColor('grey.60');
        display: block;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%) rotate(-45deg);
        margin-top: -2px;
      }
    }
  }
}

.tabulator {
  border: none;
  background: getColor('white.base');

  .text-center {
    text-align: center !important;
  }
  .tabulator-header {
    border-color: getColor('grey.40');
    background: getColor('white.base');
    position: sticky;
    top: 0;

    .tabulator-col {
      background: getColor('white.base');
      color: getColor('primary.base');
      height: 32px !important;
      padding: 0.375rem getSpacing('4x');
      border-color: getColor('grey.15');

      &.highlight-col {
        background: getColor('secondary.40');
      }

      &.has-search-value {
        background: getColor('primary.10');
      }

      &.tabulator-sortable {
        &:hover {
          background: getColor('white.base');
        }

        &.tabulator-col-sorter-element {
          &:hover {
            background: getColor('primary.10');
          }
        }

        .tabulator-col-title {
          padding-right: 35px;
        }
      }

      &.action-btn-cell {
        padding-left: 0;
        padding-right: 0;
        border-right: 0;
      }

      &.action-logs-cell {
        border-right: 0;
      }

      .tabulator-col-content {
        padding: 0;

        .tabulator-header-popup-button {
          position: absolute;
          top: 0.125rem;
          right: 1.125rem;
          width: 1.125rem;
          height: 1.125rem;
          padding: 0;
          border-radius: 2px;
          @include display-flex(row, center, center);

          &:hover {
            opacity: 1;
            background: getColor('primary.15');
            svg {
              path {
                fill: getColor('primary.60');
              }
            }
          }
        }
      }
    }
  }

  .tabulator-alert {
    background: none !important;
  }

  .tabulator-alert-msg {
    border: none !important;
    color: white !important;
    background: none !important;
  }
}

.tabulator-header-filter {
  display: none;
}

.tabulator-col-title-holder {
  display: flex;
}

.tabulator-popup {
  padding: getSpacing('2x');
  margin-top: 13px;
  margin-left: -20px;

  .icon-filter-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    pointer-events: none;
  }
  > .input-filter-search {
    height: 37px;
    padding: 0 getSpacing('3x');
    border: 1px solid getColor('grey.40');
    border-radius: 2px;
    padding-left: getSpacing('8x');
  }
}

// Edit button

.actions-btn {
  margin-top: -3px;
  display: inline-block;
}

// Tooltip

.table__tooltip {
  background: getColor('grey.base');
  border-radius: 2px;
  padding: 0.375rem 0.25rem 0.25rem;
  color: getColor('white.base');
  line-height: 1;
  border: none;
}

.tabulator-calc-table {
  .tabulator {
    .tabulator-footer {
      padding: 0;
      margin: 0;
    }
  }
}
