.card {
  background-color: white;
  @include e('header') {
    border-bottom: 1px solid getColor('grey.15');
    padding: 24px;
    padding-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
  }
  
  @include e('section') {
    padding: 24px;
    padding-top: 16px;
  }
}
