// Custom font url
// $font-url: "https://rsms.me/inter/inter.css" !default;
//$font-url: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap' !default;

$typefaces: (
  'Montreal-Regular': (
    family: 'Montreal',
    weight: 400,
    style: normal,
  ),
  'Montreal-Bold': (
    family: 'Montreal',
    weight: 700,
    style: normal,
  ),
  'Montreal-DemiBold': (
    family: 'Montreal',
    weight: 600,
    style: normal,
  ),
  'UntitledSans-Regular': (
    family: 'UntitledSans',
    weight: 400,
    style: normal,
  ),
  'UntitledSans-Bold': (
    family: 'UntitledSans',
    weight: 700,
    style: normal,
  ),
  'UntitledSans-RegularItalic': (
    family: 'UntitledSans-Bold',
    weight: 400,
    style: italic,
  ),
);

// Font Declaration
$font-primary: 'UntitledSans', sans-serif;
$font-secondary: 'Montreal', sans-serif;

$font-sans: $font-primary, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  sans-serif;

$font-sizes: (
  h1: (
    md: 2.25rem,
  ),
  h2: (
    md: 2rem,
  ),
  h3: (
    md: 1.5rem,
  ),
  h4: (
    md: 1.25rem,
  ),
  h5: (
    md: 1.125rem,
  ),
  h6: (
    md: 1rem,
  ),
  p: (
    xs: 0.875rem,
  ),
);

//font-weight
$fontWeight: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
);

$fontDecoration: (
  striked: line-through,
  underline: underline,
);

$fontCase: (
  uppercase: uppercase,
  lowercase: lowercase,
  capitalize: capitalize,
);

$fontStyle: (
  normal: normal,
  italic: italic,
  oblique: oblique,
);

$textAlignment: (
  center: center,
  left: left,
  right: right,
  justify: justify,
);
