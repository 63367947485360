@if ($generate-color-classes) {
  @each $name, $color in $colorMap {
    @each $colorKey, $colorValue in $color {
      .#{$prefix}color-#{$name}--#{$colorKey} {
        color: $colorValue;
      }

      .#{$prefix}bg-#{$name}--#{$colorKey} {
        background-color: $colorValue;
      }
    }
  }

}
