.user-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 32px;
  width: fit-content;

  @include mqmax(sm) {
    grid-template-columns: 1fr;
  }

  @include e('form-buttons') {
    display: flex;
    justify-content: flex-end;
  }

  input {
    min-width: 350px;

    &:disabled {
      background: getColor('grey.10');
    }
  }

  @include e('error') {
    border: 1px solid getColor('danger.base');
  }

  @include e('dropdown') {
    width: 350px;
  }

  @include e('back') {
    border: 1px solid getColor('primary.80');
  }

  @include e('error-msg') {
    color: getColor('danger.base');
  }
}

.add-user-form {
  hr {
    border: 0;
    outline: 0;
    height: 0;
    border-bottom: 1px solid getColor('grey.20');
  }
}
