//Font Imports
//Provide url in '$font-url--google' variable from '_config.scss' partial
@if variable-exists(font-url--google) {
	@import url($font-url--google); // CSS font import from Google CDN
}

@if variable-exists(typefaces) {
	@each $file, $option in $typefaces {
		@font-face {
			font-family: map-get(map-get($typefaces, $file), family);
			font-style: map-get(map-get($typefaces, $file), style);
			font-weight: map-get(map-get($typefaces, $file), weight);
			src: url('../../../fonts/#{$file}.woff') format('woff'), url('../../../fonts/#{$file}.woff') format('woff2');
		}
	}
}
