.client-dashboard-main {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  .col-span-6 {
    grid-column: span 6 / span 6;
    color: getColor('primary.base');
  }
  .col-span-9 {
    grid-column: span 9 / span 9;
    color: getColor('primary.base');
  }
  .col-span-3 {
    grid-column: span 3 / span 3;
    color: getColor('primary.base');
  }
}

.client-dashboard__graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 452px;
  width: 100%;
}

.fallback-container {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  height: 100%;
  color: getColor('grey.30');
}
