.user-management-pill {
  height: 23px;
  display: flex;
  font-size: 12px;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
}

.user-management {
  .tabulator-cell {
    align-content: center;
    color: getColor('grey.base');
  }
}
