.mfa {
  @include e('page') {
    height: 100vh;
  }

  @include e('right') {
    flex-direction: column;
    flex: 1;
  }

  @include e('left') {
    flex: 1;
  }

  @include e('qr') {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 200px;
    }
  }

  @include e('instructions') {
    display: flex;
    flex-direction: column;

    ol {
      display: flex;
      flex-direction: column;
      list-style: decimal;
    }
  }

  @include e('right-container') {
    width: min-content;
  }
}
