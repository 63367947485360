.dashboard {
  background: getColor('white.base');
  padding: getSpacing('25x');
  max-width: 750px;
  margin: 130px auto 0;
  box-shadow: 0px 2px 8px rgba(184, 184, 184, 0.2);

  form {
    max-width: 270px;
    margin: auto;
  }
}
