.account {
  @include e('number') {
    @include e('input') {
      border: 1px solid getColor('grey.40');
      padding: getSpacing('1x') getSpacing('2x');
      height: 33px;
      font-size: 0.875rem;
      background: transparent;
    }

    @include e('box') {
      position: relative;

      &:after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 20px;
        background: getColor('grey.20');
        margin: 0 getSpacing('5x');
      }
    }
  }

  @include e('sidebar') {
    .rowlist__header {
      color: getColor('ever-green.80');
    }

    .rowlist__label {
      color: #3e3e40;
    }
  }

  @include e('tabs') {
    min-height: 336px;
    box-shadow: 0px 2px 8px 0px getColor('grey.15');
    border-radius: 4px;

    .tabulator-header {
      border-top: 1px solid getColor('grey.40');
    }

    .column-selector {
      padding: 8px 16px 8px 12px;
    }
  }

  @include e('tab-panel') {
    border: 1px solid getColor('grey.15');
    border-top: none;
  }
}

.right-side {
  min-width: 305px;
}

.left-side {
  flex: 1;
  min-width: 300px;
}
