.empty-page {
  @include e('content') {
    @include display-flex(column, center, center);
    background: getColor('white.base');
    box-shadow: 0px 2px 8px rgba(184, 184, 184, 0.2);
    border-radius: 4px;
    flex-grow: 1;
  }
}

.empty__unstyled {
  @include display-flex(column, center, center);
  height: calc(100% - 50px);
}
