.tag {
  @include e('wrapper') {
    @include display-flex(row, center);
    @extend .text-sm;
  }

  @include e('label') {
    color: getColor('grey.60');
  }

  @include e('list') {
    @extend .flex-horizontal;
    @include e('item') {
      @extend .flex-horizontal;
      @extend .text-sm;
      background: getColor('grey.10');
      padding: 0.3125rem getSpacing('3x') 0.25rem;
      border-radius: 5rem;
      color: getColor('grey.base');
      white-space: nowrap;
      cursor: default;

      &:hover {
        background: getColor('grey.15');
      }
    }
  }

  @include e('dropdown') {
    .tag__list__button {
      @extend .text-sm;
      background: transparent;
      position: relative;
      padding: 2px 16px 2px 4px;
      color: getColor('primary.base');

      &:hover {
        background: getColor('primary.10');
        border-radius: 3px;
      }

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        border-left: 1px solid getColor('grey.60');
        border-bottom: 1px solid getColor('grey.60');
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        right: 6px;
        margin-top: -3px;
      }
    }
  }

  @include e('dropdown') {
    position: relative;
  }

  @include e('dropdown__list') {
    @include display-flex(column, flex-start);
    background: getColor('white.base');
    box-shadow: 0 0 8px rgba(getColor('black.base'), 0.2);

    position: absolute;
    top: 100%;
    left: 0;

    .tag__list__item {
      background: none;
      text-align: left;
    }
  }
}
