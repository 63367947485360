.tabs {
  @include e('tab-list') {
    display: flex;
    justify-content: space-between;
    list-style: none;
    background: getColor('grey.5');
    border: 1px solid getColor('grey.15');
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  @include e('tab') {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    background: getColor('grey.5');
    font-size: 16px;
    font-weight: 700;
    color: getColor('grey.60');

    &:not(:first-child) {
      border-left: 1px solid getColor('grey.15');
    }

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
    }

    &:not(.tabs__tab--active) {
      &:hover {
        background: getColor('grey.10');
      }
    }

    @include m('active') {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background: getColor('primary.10');
      color: getColor('primary.base');
      border-bottom: 4px solid getColor('primary.base');

      // removes the left border from the tab after the active tab
      & ~ .tabs__tab {
        border-left: none;
      }

      // restores the left border for non-adjacent tabs
      // The `~` combinator selects all sibling elements that follow the current element.
      // The `+` combinator is used to skip the immediate sibling and target the next one.
      & ~ .tabs__tab + .tabs__tab {
        border-left: 1px solid getColor('grey.15');
      }

      // Remove border for the tab immediately preceding the active tab
      &:not(:first-child) {
        border-left: none;
      }
    }
  }
}
