.work-tracker-container {
  background-color: getColor('primary.2');
}

.work-tracker-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  @include e('dropdown') {
    width: 100%;
  }

  @include e('textarea') {
    width: 100%;
    grid-column: span 2;
  }

  @include e('text') {
    width: 100%;
  }

  @include e('full-row') {
    grid-column: span 2;
    width: 100%;
  }

  @include e('post-details') {
    grid-column: span 2;
    display: grid;
    grid-gap: 15px;

    grid-template-columns: 1fr 1fr 1fr;
  }

  overflow-y: auto;
  min-height: 430px;
}

.work-tracker {
  @include e('table') {
    border: 1px solid getColor('grey.15');
  }
}
