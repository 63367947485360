$process-left-padding: 1.25rem;

.aside {
  background: getColor('primary.base');
  position: fixed;
  top: 0;
  left: 0;
  width: $aside-width;
  height: 100vh;
  transition: width 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @include e('nav') {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  @include e('nav__link') {
    color: getColor('white.base');
    padding: getSpacing('2x') getSpacing('5x');
    display: block;
    font-size: 0.875rem;
    white-space: nowrap;
    margin-bottom: getSpacing('3x');
    border-left: 3px solid transparent;

    &:hover {
      background: rgba(getColor('grey.5'), 0.2);
      color: getColor('white.base');
    }

    &.active {
      background: rgba(getColor('grey.5'), 0.2);
      color: getColor('white.base');
      border-color: getColor('primary.40');
    }

    @include e('span') {
      display: block;
      position: relative;
      padding-left: 20px;
      pointer-events: none;

      svg {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        margin-top: -2px;
      }
    }

    .nav__text {
      display: block;
    }
  }

  .brand-logo-mini {
    display: none;
  }

  &.shrink {
    width: $aside-width-sm;

    .aside__nav__link {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .aside__nav__link__span {
      padding-left: 0;

      svg {
        position: static;
        margin-right: 0 !important;
        transform: none;
        width: 18px;
        height: 18px;
      }
    }
    .nav__text {
      display: none;
    }

    .brand-logo {
      display: none;
    }

    .brand-logo-mini {
      display: inline-block;
    }

    .aside__toggler {
      svg {
        transform: scaleX(-1);
        margin: 0;
      }
    }

    .process__number {
      display: block;
      margin: 0;
    }

    .process__nav {
      .aside__nav__link {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 0.75rem;
        text-align: center;
        position: relative;
        white-space: break-spaces;

        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .popover-container {
      display: none;
    }
  }

  // Toggler

  @include e('toggler') {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: getColor('primary.15');
    border: none;
    position: absolute;
    top: getSpacing('4x');
    right: -15px;
    box-shadow: 2px 1px 4px 0 rgba(#cfcfcf, 0.15);
    transition: box-shadow 0.4s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 2px 2px 5px 0 rgba(#cfcfcf, 1);
    }
  }

  hr {
    border: 0;
    outline: 0;
    height: 0;
    border-bottom: 1px solid getColor('primary.80');
  }

  .scroll__thumb__vertical {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:hover {
    .scroll__thumb__vertical {
      visibility: visible;
      opacity: 1;
    }
  }
}

//Logout Menu
.logout {
  color: getColor('white.base');
  padding: getSpacing('2x') getSpacing('5x');
  display: block;
  font-size: 0.875rem;
  white-space: nowrap;
  margin-bottom: getSpacing('3x');
  border-top: 1px solid getColor('primary.20');
  border-left: 3px solid transparent;
  text-align: left;

  &:hover {
    background: rgba(getColor('grey.5'), 0.2);
    color: getColor('white.base');
  }
}

// Process Menu

.nav__title {
  color: getColor('primary.15');
  margin: 0 getSpacing('5x') getSpacing('4x');

  .shrink & {
    margin-left: getSpacing('2x');
    margin-right: getSpacing('2x');
    font-size: 0.75rem;
    text-align: center;
  }
}

.process {
  @include e('nav') {
    .aside__nav__link {
      padding-left: $process-left-padding;
      position: relative;
      &:after {
        content: '';
        width: 0.125rem;
        height: 0.75rem;
        background: #546071;
        position: absolute;

        bottom: -12px;
        left: calc($process-left-padding + 4px);
      }
    }

    .aside__nav__item {
      &:last-child {
        .aside__nav__link {
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  @include e('number') {
    font-size: 1rem;
    font-weight: 700;
    margin-right: getSpacing('4x');
    position: relative;
  }
}

.sidebar-username {
  @include text-shorten;
  max-width: 116px;
}
