.tooltip{
    background: #000;
    padding: 10px;
    border: 1px solid #ccc;
    color: getColor('white.base');
    transition: all 0.1s ease-in-out;

    .count{
        display: inline;
        width: 12px;
        height: 12px;
        background-color: getColor('secondary.base');
        margin-right: 8px;
    }

    .date{
        color: getColor('white.base');
        font-size: 16px;
        font-weight: bold;
    }

    .balance{
        display: inline;
        width: 12px;
        height: 12px;
        background-color: getColor('red.base');
        margin-right: 8px;
    }

}
