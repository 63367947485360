.switcher-filters {
  display: flex;
  gap: getSpacing('10x');

  .switcher-filter {
    display: flex;
    gap: getSpacing('2x');
  
    .select-financial {
      width: 172px;
    } 
  }
}

.switcher__label {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  input {
    display: none;

    &:checked {
      & + .switch {
        background: getColor('primary.15');
        .dot {
          --offset: 14px;

          &:before {
            background: getColor('primary.base');
          }
        }
      }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 12px;
    width: 26px;
    border-radius: 10px;
    background: getColor('grey.10');

    &.switch--medium {
      height: 18px;
      width: 32px;
    }
    .dot {
      background: getColor('grey.20');
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 2px;
      top: 2px;
      transform: translateX(var(--offset, 0));
      transition: transform 0.4s, box-shadow 0.4s;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        background: getColor('grey.20');
        opacity: 1;
        transition: opacity 0.4s;
      }
      &.dot--medium {
        height: 14px;
        width: 14px;
      }
    }
  }
  .switcher__text {
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 2px;
    transition: color 0.4s;
  }
}

.switch-container {
  padding: 4px 8px;
  border-radius: 4px;
  color: getColor('grey.80');

  &:hover {
    background: getColor('primary.10');
  }

  &.selected {
    background: getColor('primary.10');

    color: getColor('primary.base');
    svg {
      path {
        fill: getColor('primary.base');
      }
    }
  }

  svg {
    path {
      fill: getColor('primary.40');
    }
  }

  @include m('tabbed') {
    border-radius: 50px;
    padding: 6px 12px;
    font-weight: 400;

    color: getColor('grey.base');

    .badge {
      color: getColor('primary.base');
    }

    &:not(.selected):hover {
      background: getColor('grey.10');

      .badge {
        background: getColor('grey.20');
      }
    }
  }
}
