.rowlist {
  @include e('lists') {
    margin-left: -#{getSpacing('2x')};
  }
  @include e('item') {
    display: flex;
    padding: getSpacing('1x') getSpacing('2x');

    &:hover {
      background: rgba(getColor('primary.base'), 0.05);
      cursor: default;
    }
  }

  @include e('header') {
    color: getColor('primary.80');
  }

  @include e('label') {
    margin-right: getSpacing('4x');
    color: getColor('primary.60');
    display: inline-block;
  }
}

.value-right {
  .rowlist__value {
    margin-left: auto;
  }
}
