.accordion {
  border-radius: 4px;
  overflow: hidden;

  @include e('button') {
    background-color: getColor('primary.10');
    box-shadow: rgba(184, 184, 184, 0.2);
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include e('content') {
    transition: height 0.35s ease-in-out;
  }

  @include e('caret') {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: getColor('primary.15');
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transition: transform 0.35s ease-in-out;

    @include m('open') {
      transform: rotate(90deg);
    }
  }
}
