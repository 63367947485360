.dropdown {
  position: relative;
  padding-right: 25px;
  cursor: pointer;

  &:after {
    content: '';
    border: solid getColor('grey.60');
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 3px;
    right: 5px;
  }

  &.no-arrow {
    padding-right: 0;
    &::after {
      display: none;
    }
  }

  @include e('list') {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 193px;
    background: getColor('white.base');
    z-index: 1;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(getColor('black.base'), 0.2);
    font-size: 0.875rem;

    li {
      &:hover {
        background: getColor('primary.10');
      }

      button {
        display: block;
        padding: getSpacing('3x') getSpacing('2x');
        color: getColor('grey.base');
        width: 100%;
        text-align: left;
      }

      &.active {
        background: getColor('primary.10');
        position: relative;

        &::after {
          content: '';
          display: inline-block;
          transform: rotate(45deg);
          height: 12px;
          width: 5px;
          border-bottom: 3px solid getColor('grey.40');
          border-right: 3px solid getColor('grey.40');

          position: absolute;
          top: 12px;
          right: 16px;
        }
      }
    }

    label {
      display: block;
      cursor: pointer;
      padding: getSpacing('3x') 10px;
    }
  }

  @include e('right') {
    left: auto;
    right: 0;
  }

  @include e('account-queue-dropdown') {
    width: max-content;
  }
}
