// This will generate utility class for fonts Weight
// .${prefix}-fw-thin
// Example:
// .prefix-fw-thin{
//    font-weight: 100;
// }
//

@mixin generate-font-weight($map: $fontWeight) {
	@each $name, $value in $map {
		.#{$prefix}fw-#{$name} {
			//@include getFontWeight("#{$name}");
			font-weight: $value;
		}
	}
}
@if ($generate-font-weight-classes) {
	@include generate-font-weight;
}

// This will generate utility class for text-decorations
// .${prefix}-text-striked
// Example:
// .prefix-text-striked{
//    text-decoration:line-through;
// }
//
@mixin generate-font-decoration-class($map: $fontDecoration) {
	@each $name, $value in $map {
		.#{$prefix}text-#{$name} {
			text-decoration: $value;
		}
	}
}
@if ($generate-font-decoration-classes) {
	@include generate-font-decoration-class;
}

// This will generate utility class for text-decorations
// .${prefix}-text-uppercase
// Example:
// .prefix-text-uppercase{
//    text-transform: uppercase;
// }
//
@mixin generate-font-case-class($map: $fontCase) {
	@each $name, $value in $map {
		.#{$prefix}text-#{$name} {
			text-transform: $value;
		}
	}
}
@if ($generate-font-case-classes) {
	@include generate-font-case-class;
}

// This will generate utility class for font-style
// .${prefix}-text-italic
// Example:
// .prefix-text-italic{
//    font-style: italic;
// }
//
@mixin generate-font-style-class($map: $fontStyle) {
	@each $name, $value in $map {
		.#{$prefix}text-#{$name} {
			font-style: $value;
		}
	}
}
@if ($generate-font-style-classes) {
	@include generate-font-style-class;
}

// This will generate utility class for text-alignments
// .${prefix}-text-center
// Example:
// .prefix-text-center{
//    text-align: center;
// }
//
@mixin generate-text-alignment-class($map: $textAlignment) {
	@each $name, $value in $map {
		.#{$prefix}text-#{$name} {
			text-align: $value;
		}
	}
}
@if ($generate-text-alignment-classes) {
	@include generate-text-alignment-class;
}

// This will generate utility class for font-weight
// .${prefix}-font-bold
// Example:
// .prefix-text-bold{
//    font-weight: bold;
// }
//
@mixin generate-font-weight-class($map: $fontWeight) {
	@each $name, $value in $map {
		.#{$prefix}text-#{$name} {
			font-weight: $value;
		}
	}
}
@if ($generate-font-weight-classes) {
	@include generate-font-weight-class;
}

.capitalize-first-letter {
  text-transform: capitalize;
}
