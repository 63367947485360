div.react-select {
  @include e('indicator') {
    color: getColor('grey.80');
  }

  @include e('control') {
    border-color: getColor('grey.20');
    border-radius: 2px;

    @include m('is-disabled') {
      background: getColor('grey.10');
    }
  }

  @include e('placeholder') {
    color: getColor('grey.20');
  }


  @include e('option') {
    color: getColor('grey.base');
    position: relative;

    &:active {
      background: getColor('primary.10');
    }

    @include m('is-focused') {
      background: getColor('primary.10');
    }

    @include m('is-selected') {
      font-weight: 600;
      background: getColor('primary.10');

      &:after {
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        height: 12px;
        width: 5px;
        border-bottom: 3px solid getColor('grey.40');
        border-right: 3px solid getColor('grey.40');

        position: absolute;
        top: 8px;
        right: 16px;
      }
    }
  }
}

.select__fixed__width {
  width: 175px;
}
