.badge {
  background: getColor('primary.15');
  padding: 4px 6px 1px;
  border-radius: 50px;
  line-height: 1;
  min-width: 20px;
  text-align: center;

  &-lg {
    padding: 0.375rem getSpacing('3x') 0.1875rem;
  }

  &.highlight {
    background: getColor('warning.base')
  }

  &.error {
    background: getColor('danger.base')
  }
}
