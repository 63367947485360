:root {
  --toastify-color-error: #{$red};
  --toastify-color-info: #{$white};
  --toastify-color-success: #{$white};
  --toastify-text-color-info: #{$black};
  --toastify-text-color-success: #{$black};
  --toastify-text-color-warning: #{$black};
  --toastify-text-color-error: #{$white};
  --toastify-toast-width: fit-content;
  --toastify-toast-min-height: 46px;
  --toastify-toast-bd-radius: 0px;
}

.Toastify__toast-body {
  min-width: 468px;
}

.Toastify__toast--error {
  background: getColor('danger.base');
  color: getColor('white.base');

  .btn-link {
    color: getColor('white.base');
  }

  .btn-primary {
    background: getColor('white.base');
    color: getColor('ever-green.base');

    &:hover {
      background: getColor('primary.base');
      color: getColor('white.base');
    }
  }
}
.notify {
  @include display-flex(row, center, space-between);

  @include e('title') {
    font-size: 12px;
    font-weight: 600;
    font-family: $font-primary;
  }

  @include e('actions') {
    @include display-flex(row, center, center);
  }

  .btn {
    padding: 6px getSpacing('3x') 6px;
    font-weight: 400;
    font-family: $font-primary;
  }
}
