.data-hints {
  margin-top: 12px;

  @include e('title') {
    font-size: 36px;
    font-weight: 400;
    font-family: $font-primary;
  }

  @include e('form') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 32px;
    width: fit-content;

    @include mqmax(sm) {
      grid-template-columns: 1fr;
    }

    input {
      min-width: 350px;

      &:disabled {
        background: getColor('grey.10');
      }
    }
  }

  @include e('back') {
    border: 1px solid getColor('primary.80');
  }

  textarea {
    padding: getSpacing('2x');
    line-height: 31px;
    font-family: 'Source Code Pro', monospace;
    font-size: 1rem;
    white-space: pre-wrap;
    width: 100%;
    border: 1px solid getColor('grey.20');
    border-radius: 2px;
    resize: none;
    padding: 10px 12px;
    height: 464px;
  }

  hr {
    border: 0;
    outline: 0;
    height: 0;
    border-bottom: 1px solid getColor('grey.20');
  }

  @include e('filter-container') {
    background: getColor('white.base');
    padding: 8px 10px;
    margin-bottom: 4px;
    box-shadow: 0px 2px 8px 0px getColor('grey.10');
  }

  @include e('definition-header-content') {
    border-right: 1px solid getColor('primary.15');
  }
}
