.toast {
  @include display-flex(row, center, space-between);
  max-width: 485px;
  width: 100%;
  background: getColor('white.base');
  border: 1px solid getColor('grey.10');
  padding: getSpacing('2x') getSpacing('4x');
  position: fixed;
  top: getSpacing('2x');
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  box-shadow: -2px 4px 22px rgba(184, 184, 184, 0.2);
  border-radius: 2px;
  color: getColor('grey.base');

  @include e('title') {
    font-size: 12px;
    font-weight: 600;
  }

  @include e('actions') {
    @include display-flex(row, center, center);
  }

  .btn {
    padding: 6px getSpacing('3x') 6px;
    font-weight: 400;
    font-family: $font-primary;
  }

  &.has-error {
    background: getColor('danger.base');
    color: getColor('white.base');

    .btn-link {
      color: getColor('white.base');
    }

    .btn-primary {
      background: getColor('white.base');
      color: getColor('ever-green.base');

      &:hover {
        background: getColor('primary.base');
        color: getColor('white.base');
      }
    }
  }
}
