.table-50vh {
  max-height: 50vh;
  flex: 1;
}
.table-25vh {
  max-height: 25vh;
  flex: 1;
}

.title-18 {
  font-size: 1.125rem;
}

.textarea__box {
  background: getColor('white.base');
  padding: getSpacing('3x') getSpacing('4x') getSpacing('3x');
  box-shadow: 0px -2px 8px rgba(184, 184, 184, 0.2);
  @include display-flex(column);
  flex: 1;

  textarea {
    padding: getSpacing('2x');
    line-height: 1.2;
    font-family: 'Source Code Pro', monospace;
    font-size: 1rem;
    white-space: pre-wrap;
    width: 100%;
    border: 1px solid getColor('grey.15');
    border-radius: 3px;
    height: 100%;
    resize: none;

    &[readonly] {
      border: none;
      padding: 0 1.25rem 0 0;
    }

    &.font-secondary {
      font-family: $font-primary;
    }

    &.h-calc-50 {
      height: calc(100% - 50px);
    }
  }

  .empty-page__content {
    border: 1px solid getColor('grey.15');
  }
}

.white__box {
  @extend .textarea__box;
}
