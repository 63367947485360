.stat {
  @include e('card') {
    @include e('header') {
      background: getColor('primary.10');
      padding: getSpacing('2x') getSpacing('4x');
      font-weight: 600;
    }

    @include e('body') {
      background: getColor('primary.2');
      padding: getSpacing('3x') getSpacing('5x');
    }
  }

  @include e('list') {
    @include e('item') {
      margin-bottom: getSpacing('3x');

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include e('label') {
      font-weight: 600;
      color: #3e3e40;
      font-size: 1rem;
      display: inline-block;
      min-width: 190px;
    }

    @include e('value') {
      font-weight: 600;
      font-size: 1rem;
      color: getColor('grey.base');
    }
  }

  @include e('empty__list') {
    color: getColor('grey.60');
    font-weight: 600;
    font-size: 1rem;
    min-height: 100px;
    line-height: 20px;
    @include display-flex(column, center, center);
  }
}
