.popover {
  @include e('content') {
    position: absolute;
    z-index: 1000;
    white-space: nowrap;
  }

  @include e('username') {
    padding: 20px 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
}
