// ==========================================================================
// Base
// A Base rule is applied to HTML element using an element selector, a
// descendent selector, or a child selector, along with any pseudo-classes.
// We cannot reference any element defined here by their respective class or ID name.
// This defines the default styling for how that element should look.
// Changes in style other than default should be overwritten using other partials.
// ==========================================================================

// Reset CSS
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // Global Clearfixing
  @if $grid-type == float {
    &:after {
      clear: both;
      content: '';
      display: block;
    }
  }
}

// Base Code
html,
body {
  font-size: 100%;
}

html.full-height {
  height: 100%;
  body {
    height: 100%;
  }
}

*:focus-visible {
  outline: none;
}

body {
  -webkit-font-smoothing: antialiased;
  color: getColor('grey.base');
  font-family: $font-primary;
  font-size: $base__font-size;
  text-rendering: optimizeLegibility;
  line-height: $base__body__line;
  height: 100vh;
  background: getColor('grey.5');
}

a {
  outline: none;
  color: getColor('primary.base');
  text-decoration: none;
  &:hover {
    color: getColor('primary.80');
  }
}

@if $grid-type == float {
  a,
  span,
  strong {
    &:after {
      @extend %clearfix-none;
    }
  }
}

ul,
ol {
  list-style: none;
  @include getFontSize('p');
}

ul.enable-style {
  list-style: disc;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}
.h-50 {
  height: 50%;
}
.h-auto {
  height: auto;
}

.minw-150 {
  min-width: 9.375rem;
}

.line-height-30 {
  line-height: 1.875rem;
}

.line-height-1 {
  line-height: 1;
}

.text-sm {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.25rem;
}

.icon-export {
  position: relative;
  top: 1px;
}

.icon-column {
  position: relative;
  top: 2px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.has-box-shadow {
  box-shadow: 0px 2px 8px rgba(184, 184, 184, 0.2);
}

.font-primary {
  font-family: $font-primary;
}

.box-shadow--none {
  box-shadow: none !important;
}

.flex-horizontal {
  @include display-flex(row, center, center);
}

.row.row-12 {
  margin-left: -12px;
  margin-right: -12px;

  > [class*='col-'] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

pre {
  padding: getSpacing('4x');
  line-height: 1.9375rem;
  font-family: 'Source Code Pro', monospace;
  font-size: 1rem;
  white-space: pre;
  width: 100%;
  word-break: break-all;
  border: 1px solid getColor('grey.15');
  border-radius: 3px;
}

.seperator {
  width: 1px;
  background: getColor('grey.15');
  align-self: stretch;
}

.action-link {
  &:hover {
    text-decoration: none;
  }
}

[
  role = 'button'
]{
  cursor: pointer;
}
