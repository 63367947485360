h1 {
  @include getFontSize('h1');
  font-weight: 600;

  span {
    font-size: 1.5rem;
  }
}

h2 {
  @include getFontSize('h2');
  font-weight: 600;
}

h3 {
  @include getFontSize('h3');
}

h4 {
  @include getFontSize('h4');
}

h5 {
  @include getFontSize('h5');
}

h6 {
  @include getFontSize('h6');
}

h1,
h2 {
  font-family: $font-secondary;
}

h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  letter-spacing: -0.5px;
}

b {
  &.font-secondary {
    font-family: $font-secondary;
  }
}

h3,
h4,
h5,
h6 {
  &.font-secondary {
    font-family: $font-secondary;
  }
}
